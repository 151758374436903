import { Block, Paragraph } from '@stage-ui/core'
import ParagraphTypes from '@stage-ui/core/content/Paragraph/types'
import BlockTypes from '@stage-ui/core/layout/Block/types'

type PageTextProps = {
  size?: string | string[]
  title?: string
  titleProps?: ParagraphTypes.Props
  textProps?: ParagraphTypes.Props
} & BlockTypes.Props

const PageText = (props: PageTextProps) => {
  const { children, size, title, titleProps, textProps, onClick, ...restProps } = props

  return (
    <Block {...restProps}>
      {!!title && (
        <Paragraph
          size={size || '1.25rem'}
          color="black"
          weight={600}
          lineHeight="180%"
          {...titleProps}
        >
          {title}
        </Paragraph>
      )}
      <Paragraph
        size={size || ['1.25rem', '1rem']}
        color="black"
        weight={300}
        lineHeight="180%"
        {...textProps}
      >
        {children}
      </Paragraph>
    </Block>
  )
}

export default PageText
