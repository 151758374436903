import { Block, Divider, Flexbox, Grid, Paragraph } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import PageTitle from '@src/components/PageTitle'
import PageText from '@src/components/PageText'
import Cube from '@src/components/Cube'

const S04Benefits = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox column {...props}>
      <PageTitle
        id="grow"
        attributes={{
          'data-id': 'grow',
        }}
        label="Преимущества компании"
        title="Рост прибыли"
      />
      <Grid gap={['4rem', '2rem']} templateColumns={['1fr 1fr', '1fr']}>
        <Block>
          <PageText>
            Отказ от грубого ручного <br />
            округления при сборке
          </PageText>
          <Paragraph
            weight={700}
            color="green600"
            size={['2.5rem', '1.5rem']}
            mt={['1rem', '0rem']}
          >
            увеличивает
          </Paragraph>
          <Paragraph
            weight={800}
            color="green600"
            size={['4.5rem', '2.5rem']}
            mt={['-1rem', '0rem']}
            mb={['1rem', '0rem']}
          >
            на 7%
          </Paragraph>
          <PageText>выручку со среднего чека.</PageText>
        </Block>
        <Divider w="5rem" display={['none', 'block']} />
        <Block>
          <PageText>
            Время от начала сборки до <br />
            выпуска водителей со склада
          </PageText>
          <Paragraph
            weight={700}
            color="green600"
            size={['2.5rem', '1.5rem']}
            mt={['1rem', '0rem']}
          >
            сокращается
          </Paragraph>
          <Paragraph
            weight={800}
            color="green600"
            size={['4.5rem', '2.5rem']}
            mt={['-1rem', '0rem']}
            mb={['1rem', '0rem']}
          >
            на 25%
          </Paragraph>
          <PageText>2 часа при 10-часовой смене</PageText>
        </Block>
        <Cube
          scale={3}
          blur={5}
          hue={300}
          reverse
          duration={30}
          style={{
            opacity: 0.6,
            position: 'absolute',
            right: '-4rem',
            top: '-10rem',
            zIndex: 2,
          }}
        />
        <Cube
          scale={1}
          hue={140}
          reverse
          duration={30}
          style={{
            opacity: 0.6,
            position: 'absolute',
            left: '25rem',
            top: '7rem',
            zIndex: 2,
          }}
        />
      </Grid>
    </Flexbox>
  )
}

export default S04Benefits
